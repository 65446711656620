<template>
  <KsPageLoading />
  <div class="chat-page" v-if="!pageLoading">
    <KsPopup
      :showPopup="showChat"
      @close="close('showChat')"
      :index="10"
      type="chat main"
      id="scroll-box"
    >
      <template #header>
        <div class="title">{{ chat.name.value }}</div>
        <div class="text">{{ chat.service.name }}</div>
      </template>
      <template #body>
        <div v-for="(message, index) in messages" :key="index">
          <div v-if="message.first_message_of_the_day == true" class="date-row">
            {{ message.first }}
          </div>
          <KsMessage
            v-if="message.client_id.is_admin == 1"
            :date="message.created_at"
            :title="
              (messages[index > 0 ? index - 1 : 0].client_id.id ==
                message.client_id.id) &
              !message.first_message_of_the_day
                ? ''
                : message.client_id.name
            "
            :text="message.content"
            :color="message.color"
          />
          <KsMessage
            v-else
            :date="message.created_at"
            :title="message.client_id.name"
            :text="message.content"
            type="question"
            color="#323232"
            @select="selectUser(message.client_id)"
          />
        </div>
      </template>
      <template #footer>
        <div class="action-row">
          <div class="action-item" @click="showTemplates = true">
            <img src="@/assets/images/icons/messages.svg" />
          </div>
          <div class="action-item" @click="showColors = true">
            <div class="text" v-if="chat.color_id.value == ''">تگ رنگ</div>
            <div
              class="color-box"
              v-else
              :style="{ backgroundColor: chat.color_id.code }"
            ></div>
          </div>
          <div
            class="action-item"
            @click="lockMessage"
            :class="{ active: !chat.open.value }"
          >
            <img class="lock" src="@/assets/images/icons/lock.svg" />
            <img src="@/assets/images/icons/unlock.svg" />
          </div>
          <div
            class="action-item"
            :class="{ active: chat.client_id.value != '' }"
            @click="showClients = true"
          >
            <img
              v-if="chat.client_id.value == ''"
              src="@/assets/images/icons/mention.svg"
            />
            <div class="assign" v-else>
              <img src="@/assets/images/icons/asign.svg" />
              <div class="assign-name">{{ chat.client_id.name }}</div>
            </div>
          </div>
        </div>
        <div class="send-message-row">
          <form @keyup.enter="messageSumbit">
            <KsTextArea
              placeholder=" پیام شما"
              :data="form.items.content"
              @updateValue="(data) => (form.items.content.value = data)"
              id="mainText"
            />
            <div class="button-row main-button-row">
              <KsLoader v-if="messageLoading" />
              <KsButton type="send" v-else @click="messageSumbit">
                <template #icon>
                  <img src="@/assets/images/icons/send.svg" />
                </template>
              </KsButton>
            </div>
          </form>
        </div>
      </template>
    </KsPopup>
    <KsPopup
      :showPopup="showColors"
      @close="close('showColors')"
      :index="11"
      type="chat"
    >
      <template #header>
        <div class="title">ویژه سازی شخصی</div>
      </template>
      <template #body>
        <ul class="popup-list">
          <li
            class="popup-item"
            v-for="(item, index) in menu.colors"
            :key="index"
            @click="selectColor(item)"
          >
            <span class="color-box" :style="{ borderColor: item.code }"> </span>
            <span class="text"> {{ item.name }}</span>
          </li>
        </ul>
      </template>
    </KsPopup>
    <KsPopup
      :showPopup="showClients"
      @close="close('showClients')"
      :index="12"
      type="chat"
    >
      <template #header>
        <div class="title">سپردن به</div>
      </template>
      <template #body>
        <ul class="popup-list">
          <li
            class="popup-item client-item"
            v-for="(item, index) in clients"
            :key="index"
            @click="selectClient(item)"
          >
            <img src="@/assets/images/icons/mention.svg" />
            <span class="text"> {{ item.name }}</span>
          </li>
        </ul>
      </template>
    </KsPopup>
    <KsPopup
      :showPopup="showTemplates"
      @close="close('showTemplates')"
      :index="13"
      type="chat"
    >
      <template #header>
        <div class="title">پیام‌های آماده</div>
      </template>
      <template #body>
        <KsTextArea
          placeholder=" افزودن پیام آماده جدید "
          :data="templateForm.items.content"
          @updateValue="(data) => (templateForm.items.content.value = data)"
        />
        <div class="button-row">
          <KsLoader v-if="loading" />
          <KsButton type="send" v-else @click="templateSubmit">
            <template #icon>
              <img src="@/assets/images/icons/send.svg" />
            </template>
          </KsButton>
        </div>
        <KsTemplate
          v-for="(item, index) in templates"
          :key="index"
          :item="item"
          @click="selectTemplate(item)"
        >
        </KsTemplate>
      </template>
    </KsPopup>
    <KsPopup
      :showPopup="showUser"
      @close="close('showUser')"
      :index="13"
      type="chat"
    >
      <template #header>
        <div class="title">اطلاعات کاربر</div>
      </template>
      <template #body>
        <div
          class="user-details"
          v-for="(item, index) in userDeta"
          :key="index"
        >
          <div class="items">
            <div>{{ item.key }}:</div>
            <div>{{ item.value }}</div>
          </div>
        </div>
        <KsTextArea
          placeholder=" یاداشت"
          :data="userForm.items.content"
          @updateValue="(data) => (userForm.items.content.value = data)"
        />
        <div class="button-row">
          <KsLoader v-if="userLoading" />
          <KsButton type="send" v-else @click="createUserMemo">
            <template #icon>
              <img src="@/assets/images/icons/send.svg" />
            </template>
          </KsButton>
        </div>
        <KsTemplate
          v-for="(item, index) in userMemos"
          :key="index"
          :item="item"
        >
        </KsTemplate>
      </template>
    </KsPopup>
    <div class="filter-menu" :class="{ active: mobileMenu }">
      <div class="title">
        <img src="@/assets/images/icons/title.svg" />
      </div>
      <div class="title-row">
        <div class="icon-box">
          <img class="icon" src="@/assets/images/icons/service.png" />
        </div>
        <div class="text-item">سرویس‌ها</div>
      </div>
      <ul class="filter-list">
        <li
          class="list-item"
          :class="{ active: serviceActive == index }"
          v-for="(item, index) in menu.services"
          :key="index"
          @click="getService(index, item.id)"
        >
          <span class="icon-box">
            <img class="icon" src="@/assets/images/icons/hashtag.svg" />
          </span>
          <span class="text">{{ item.name }}</span>
          <span class="number">{{
            item.unread_messages.toLocaleString("fa-ir")
          }}</span>
        </li>
      </ul>
      <div class="title-row">
        <div class="icon-box">
          <img class="icon" src="@/assets/images/icons/file.png" />
        </div>
        <div class="text-item">ویژه سازی با رنگ برای شما</div>
      </div>
      <ul class="filter-list color-list">
        <li
          class="list-item"
          v-for="(item, index) in menu.colors"
          :key="index"
          @click="getColor(index, item.id)"
          :class="{ active: colorActive == index }"
        >
          <span class="color-box" :style="{ borderColor: item.code }"> </span>
          <span class="text"> {{ item.name }}</span>
        </li>
      </ul>
      <div
        class="title-row has-number"
        @click="handleAssignedToMeFilter()"
        :class="{ active: assigned_to_me_active }"
      >
        <div class="icon-box">
          <img class="icon" src="@/assets/images/icons/forme.png" />
        </div>
        <div class="text-item">سپرده شده به من</div>
        <span class="number">{{
          menu.assigned_to_me.toLocaleString("fa-ir")
        }}</span>
      </div>
    </div>
    <div class="items-row">
      <div class="back" v-if="!mobileMenu">
        <div class="icon-box" @click="closeMenu">
          <img src="@/assets/images/icons/left-arrow.svg" />
        </div>
      </div>
      <KsSearchBox type="has-icon" :reset="reset" @reset="resetSearch()">
        <template #action>
          <input
            v-model="search"
            class="search"
            type="text"
            placeholder="جستجو"
            v-on:keyup.enter="handleSearchFilter(search)"
          />
        </template>
      </KsSearchBox>
      <div class="empty-box" v-if="chats.lenght == 0">
        <img src="@/assets/images/empty.png" />
      </div>
      <div class="notfound-box" v-if="notFound">
        <img src="@/assets/images/empty.png" />
      </div>
      <div class="chat-box" v-else>
        <KsChatRow
          v-for="(chat, index) in chats"
          :key="index"
          :chat="chat"
          @click="selectChat(chat)"
        />
      </div>

      <KsPagination
        v-if="showPagination"
        :pagination="pagination"
        :current="1"
        :limit="2"
        v-on:paginate:to="setCurrent"
        :route="{ name: 'chats', query: $route.query }"
      />
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { computed, onMounted, watch } from "@vue/runtime-core";
import { notify } from "@kyvg/vue3-notification";
import { GetMenus, GetChats, ChatEdit, GetClients } from "@/api/chat.api";
import { GetTemplatesById, CreateTemplate } from "@/api/template.api";
import { GetMessages, CreateMessage } from "@/api/message.api";
import { GetMemos, CreateClientMemo } from "@/api/memos.api";

export default {
  name: "ChatPage",
  setup() {
    const chats = ref([]);
    const reset = ref(false);
    const clients = ref([]);
    const menu = ref([]);
    const service_id = ref("");
    const serviceActive = ref(null);
    const pageLoading = ref(true);
    const color_id = ref("");
    const colorActive = ref(null);
    const total_count = ref(0);
    const route = new useRoute();
    const router = new useRouter();
    const notFound = ref(false);
    const showChat = ref(false);
    const showColors = ref(false);
    const showClients = ref(false);
    const showTemplates = ref(false);
    const showPagination = ref(false);
    const assigned_to_me = ref(null);
    const assigned_to_me_active = ref(false);

    const pagination = computed(() => {
      const obj = {
        page_id: route.query.page ? route.query.page : 1,
        page_size: route.query.pagination ? route.query.pagination : 15,
        total_count: total_count.value,
      };
      obj.total_pages =
        total_count.value >= obj.page_size
          ? Math.ceil(total_count.value / obj.page_size)
          : 1;
      return obj;
    });
    const filterRoute = computed(() => {
      const obj = { name: "chats" };
      obj["query"] = {};
      obj.query["page"] = 1;
      obj.query["count"] = 15;
      if (serviceActive.value != null) {
        obj.query["service_id"] = service_id.value;
      }
      if (colorActive.value != null) {
        obj.query["color_id"] = color_id.value;
      }
      if (assigned_to_me.value != null) {
        obj.query["assigned_to_me"] = 1;
      }
      obj.query["search"] = search.value;

      return obj;
    });
    const search = ref("");
    const status = computed(() => {
      return route.query.status ? route.query.status : "";
    });
    const chat = reactive({
      id: {
        value: "",
      },
      name: {
        value: "",
      },
      service: {
        value: "",
        name: "",
      },
      color_id: {
        value: "",
        code: "",
      },
      client_id: {
        value: "",
        name: "",
      },
      open: {
        value: "",
      },
    });
    const loading = ref(false);
    const mobileMenu = ref(true);
    const messageLoading = ref(false);
    const templates = ref([]);
    const templateForm = reactive({
      disabled: true,
      items: {
        service_id: {
          value: "",
          errors: [],
        },
        content: {
          value: "",
          errors: [],
        },
      },
    });
    const form = reactive({
      disabled: true,
      items: {
        chat_id: {
          value: "",
          errors: [],
        },
        content: {
          value: "",
          errors: [],
        },
      },
    });
    const userForm = reactive({
      disabled: true,
      items: {
        user_id: {
          value: "",
          errors: [],
        },
        content: {
          value: "",
          errors: [],
        },
      },
    });
    const messages = ref([]);
    var persianDate = require("persian-date");
    const user = ref([]);
    const userDeta = ref([]);
    const showUser = ref(false);
    const userMemos = ref([]);
    const userLoading = ref(false);

    const getUserMemos = async (id) => {
      const response = await GetMemos(`client_id=${id}`);
      if (response.data.success) {
        userMemos.value = response.data.data;
      } else if (response.data.status !== 401 && response.data.message) {
        notify({
          type: "error",
          text: response.data.message,
        });
      }
    };

    const createUserMemo = async () => {
      const response = await CreateClientMemo(
        `client_id=${userForm.items.user_id.value}`,
        {
          content: userForm.items.content.value,
        }
      );
      if (response.data.success) {
        userLoading.value = true;
        setTimeout(() => {
          getUserMemos();
          userForm.items.content.value = "";
          userLoading.value = false;
        }, "1000");
      } else if (response.data.status !== 401 && response.data.message) {
        userLoading.value = false;
        notify({
          type: "error",
          text: response.data.message,
        });
      }
      form.disabled = false;
    };

    const selectUser = async (item) => {
      userDeta.value = [];
      user.value = [];
      showUser.value = true;
      user.value = item;
      userDeta.value = JSON.parse(item.payload);
      getUserMemos(item.id);
      userForm.items.user_id.value = item.id;
    };

    const getTemplates = async () => {
      const response = await GetTemplatesById(chat.service.value);
      if (response.data.success) {
        templates.value = response.data.data;
      } else if (response.data.status !== 401 && response.data.message) {
        notify({
          type: "error",
          text: response.data.message,
        });
      }
    };

    const selectChat = async (item) => {
      showUser.value = false;
      showChat.value = true;
      chat.id.value = item.id;
      form.items.chat_id.value = item.id;
      form.items.content.value = "";
      chat.open.value = item.open;
      if (item.client != null) {
        chat.client_id.value = item.client.id;
        chat.client_id.name = item.client.name;
      } else {
        chat.client_id.value = "";
        chat.client_id.name = "";
      }
      if (item.color != null) {
        chat.color_id.value = item.color.id;
        chat.color_id.code = item.color.code;
      } else {
        chat.color_id.value = "";
        chat.color_id.code = "";
      }
      if (item.service != null) {
        chat.service.value = item.service.id;
        chat.service.name = item.service.name;
        templateForm.items.service_id.value = chat.service.value;
      } else {
        chat.service.value = "";
        chat.service.name = "item.service.name;";
      }
      chat.name.value = item.name;
      getTemplates();

      getMessages();
    };

    const getMenu = async () => {
      const response = await GetMenus();
      if (response.data.success) {
        menu.value = response.data.data;
        pageLoading.value = false;
      } else if (response.data.status !== 401 && response.data.message) {
        notify({
          type: "error",
          text: response.data.message,
        });
      }
    };

    const getClients = async () => {
      const response = await GetClients();
      if (response.data.success) {
        clients.value = response.data.data;
      } else if (response.data.status !== 401 && response.data.message) {
        notify({
          type: "error",
          text: response.data.message,
        });
      }
    };

    const getService = async (index, id) => {
      service_id.value = id;

      if (serviceActive.value === index) {
        serviceActive.value = null;
        const queries = { ...route.query };
        delete queries.service_id;
        router.push({ name: "chats", query: queries });
      } else {
        serviceActive.value = index;

        const queries = { ...route.query };
        if (id) {
          queries["service_id"] = id;
        } else if ("service_id" in queries) {
          delete queries.service_id;
        }
        router.push({ name: "chats", query: queries });
      }
      setTimeout(() => {
        mobileMenu.value = false;
      }, 1400);
    };

    const getColor = async (index, id) => {
      color_id.value = id;
      if (colorActive.value === index) {
        colorActive.value = null;
        const queries = { ...route.query };
        delete queries.color_id;
        router.push({ name: "chats", query: queries });
      } else {
        colorActive.value = index;
        const queries = { ...route.query };
        if (id) {
          queries["color_id"] = id;
        } else if ("color_id" in queries) {
          delete queries.service_id;
        }
        router.push({ name: "chats", query: queries });
      }
      setTimeout(() => {
        mobileMenu.value = false;
      }, 1400);
    };

    const getChats = async (queries, pageId = 1) => {
      let query = "";
      if (queries) {
        for (const item in queries) {
          if (queries[item]) {
            if (item !== "page") {
              query += `${item}=${queries[item]}&`;
            } else {
              query += `page=${pageId ? pageId : queries[item]}&`;
            }
          }
        }
      }

      const response = await GetChats(query);
      if (response.status == 200) {
        document.querySelector(".karo-wrapper").classList.remove("isLoading");

        chats.value = response.data.data;
        total_count.value = response.data.meta.to;
        if (total_count.value > 15) {
          showPagination.value = true;
        }
        if (chats.value.length == 0) {
          notFound.value = true;
        } else {
          notFound.value = false;
        }
        document.querySelector(".karo-wrapper").classList.remove("fixed");
      } else if (response.data.status !== 401 && response.data.message) {
        notify({
          type: "error",
          text: response.data.message,
        });
      }
    };

    const handleSearchFilter = (value) => {
      const queries = { ...route.query };
      if (value) {
        queries["search"] = value;
      } else if ("search" in queries) {
        delete queries.search;
      }

      router.push({ name: "chats", query: queries });
    };

    const handleAssignedToMeFilter = () => {
      assigned_to_me_active.value = !assigned_to_me_active.value;
      if (assigned_to_me_active.value) {
        assigned_to_me.value = 1;
        const queries = { ...route.query };
        if (assigned_to_me.value) {
          queries["assigned_to_me"] = assigned_to_me.value;
        } else if ("assigned_to_me" in queries) {
          delete queries.search;
        }

        router.push({ name: "chats", query: queries });
      } else {
        assigned_to_me.value = null;
        const queries = { ...route.query };
        delete queries.assigned_to_me;
        router.push({ name: "chats", query: queries });
      }
      setTimeout(() => {
        mobileMenu.value = false;
      }, 1400);
    };

    const resetSearch = () => {
      search.value = "";
      handleSearchFilter("");
      reset.value = false;
    };

    const chatEdit = async () => {
      let params = {
        open: chat.open.value,
      };
      if (chat.client_id.value > 0) params.client_id = chat.client_id.value;

      if (chat.color_id.value > 0) {
        params.color_id = chat.color_id.value;
      }

      const response = await ChatEdit(chat.id.value, params);
      if (response.data.success) {
        getChats(filterRoute.value.query, pagination.value.page_id);
        getMenu();
      } else if (response.data.status !== 401 && response.data.message) {
        notify({
          type: "error",
          text: response.data.message,
        });
      }
    };

    const lockMessage = async () => {
      chat.open.value = !chat.open.value;
      chatEdit();
    };

    const selectColor = async (item) => {
      chat.color_id.value = item.id;
      chat.color_id.code = item.code;
      showColors.value = false;
      chatEdit();
    };

    const selectClient = async (item) => {
      chat.client_id.value = item.id;
      chat.client_id.name = item.name;
      showClients.value = false;
      chatEdit();
    };

    const createTemplate = async () => {
      const response = await CreateTemplate({
        content: templateForm.items.content.value,
        service_id: templateForm.items.service_id.value,
      });
      if (response.data.success) {
        loading.value = false;
        getTemplates();
        templateForm.items.content.value = "";
        templateForm.items.service_id.value = "";
      } else if (response.data.status !== 401 && response.data.message) {
        loading.value = false;
        notify({
          type: "error",
          text: response.data.message,
        });
      }
      loading.value = false;
      templateForm.disabled = false;
    };

    const createMessage = async () => {
      const response = await CreateMessage(form.items.chat_id.value, {
        content: form.items.content.value,
      });
      if (response.data.success) {
        messageLoading.value = false;
        getMessages();
        form.items.content.value = "";
        document.getElementById("mainText").value = "";
        document.getElementById("mainText").focus();
        getMenu();
      } else if (response.data.status !== 401 && response.data.message) {
        messageLoading.value = false;
        notify({
          type: "error",
          text: response.data.message,
        });
      }
      messageLoading.value = false;
      form.disabled = false;
    };

    const templateValidate = () => {
      let isValid = true;
      loading.value = true;
      for (const item in templateForm.items) {
        templateForm.items[item].errors = [];
      }
      if (!templateForm.items.content.value) {
        isValid = false;
        loading.value = false;
        templateForm.items.content.errors.push("فیلد خالی است .");
      }
      if (!templateForm.items.service_id.value) {
        isValid = false;
        loading.value = false;
        templateForm.items.service_id.errors.push("سرویس را انتخاب کنید.");
      }
      return isValid;
    };

    const templateSubmit = async () => {
      if (!templateValidate()) return false;
      templateForm.disabled = true;
      setTimeout(() => {
        createTemplate();
      }, 1000);
    };

    const messageValidate = () => {
      let isValid = true;
      messageLoading.value = true;
      for (const item in form.items) {
        form.items[item].errors = [];
      }
      if (!form.items.content.value) {
        isValid = false;
        messageLoading.value = false;
        notify({
          type: "error",
          text: "فیلد خالی است .",
        });
      }
      if (!form.items.chat_id.value) {
        isValid = false;
        messageLoading.value = false;
        notify({
          type: "error",
          text: "سرویس را انتخاب کنید.",
        });
      }
      return isValid;
    };

    const messageSumbit = async () => {
      if (!messageValidate()) return false;
      form.disabled = true;
      setTimeout(() => {
        createMessage();
      }, 1000);
    };

    const selectTemplate = async (item) => {
      document.getElementById("mainText").value = item.content;
      document.getElementById("mainText").focus();
      showTemplates.value = false;
      form.items.content.value = item.content;
    };

    const getMessages = async () => {
      const response = await GetMessages(chat.id.value);

      if (response.data.success) {
        messages.value = response.data.data.map((i) => {
          return {
            updatedAtDate: new persianDate(new Date(i.updatedAt)).format(
              "YYYY-MM-DD "
            ),
            created_at: new persianDate(new Date(i.created_at)).format(
              "  HH:mm"
            ),
            first_message_of_the_day: i.first_message_of_the_day,
            client_id: i.client_id,
            chat_id: i.chat_id,
            content: i.content,
            id: i.id,
            first: new persianDate(new Date(i.created_at)).format(
              `dddd  DD  MMMM  YYYY`
            ),
            last_message_date: i.created_at,
          };
        });

        setTimeout(() => {
          let questions = document.querySelectorAll(".answer .message-title");
          questions.forEach((element) => {
            var r = Math.floor(Math.random() * 255);
            var g = Math.floor(Math.random() * 255);
            var b = Math.floor(Math.random() * 255);
            element.style.color = "rgb(" + r + "," + g + "," + b + ")";
          });
          document.getElementById("scroll-box").scrollTop =
            document.getElementById("scroll-box").scrollHeight;
        }, 5);
        getMenu();
      } else if (response.data.status !== 401 && response.data.message) {
        notify({
          type: "error",
          text: response.data.message,
        });
      }
    };

    const close = async (item) => {
      eval(item + ".value " + " = " + false);
    };

    const closeMenu = async () => {
      mobileMenu.value = true;
      document.querySelector(".karo-wrapper").classList.add("fixed");
    };

    watch(
      route,
      () => {
        if (route.name === "chats") {
          getChats(filterRoute.value.query, pagination.value.page_id);
        }
      },
      {
        deep: true,
      }
    );

    watch(() => {
      if (!pageLoading.value) {
        document.querySelector(".karo-wrapper").classList.add("fixed");

        document.querySelector(".karo-wrapper").classList.remove("isLoading");
      }
      if (search.value == "" || search.value == undefined) {
        reset.value = false;
      } else {
        reset.value = true;
      }
    });

    onMounted(() => {
      colorActive.value = route.query.color_id - 1;
      serviceActive.value = route.query.service_id - 1;
      color_id.value = route.query.color_id;
      service_id.value = route.query.service_id;
      search.value = route.query.search;
      assigned_to_me.value = route.query.assigned_to_me;
      if (route.query.assigned_to_me == 1) {
        assigned_to_me_active.value = true;
      }

      if (search.value == "" || search.value == undefined) {
        reset.value = false;
      } else {
        reset.value = true;
      }
      console.log();
      if (Object.keys(route.query).length > 0) {
        mobileMenu.value = false;
      }

      getMenu();
      getClients();
      getChats(filterRoute.value.query, pagination.value.page_id);
    });

    return {
      selectColor,
      chats,
      menu,
      getService,
      service_id,
      serviceActive,
      color_id,
      colorActive,
      getColor,
      total_count,
      handleSearchFilter,
      pagination,
      filterRoute,
      status,
      showPagination,
      search,
      notFound,
      showChat,
      lockMessage,
      pageLoading,
      reset,
      chat,
      selectChat,
      close,
      showColors,
      chatEdit,
      clients,
      showClients,
      selectClient,
      loading,
      templates,
      templateForm,
      getTemplates,
      showTemplates,
      templateSubmit,
      form,
      selectTemplate,
      messages,
      messageSumbit,
      messageLoading,
      user,
      selectUser,
      showUser,
      userDeta,
      userMemos,
      userForm,
      userLoading,
      createUserMemo,
      mobileMenu,
      resetSearch,
      handleAssignedToMeFilter,
      assigned_to_me,
      assigned_to_me_active,
      closeMenu,
    };
  },
};
</script>

<style lang="scss">
@import "style.scss";
</style>