<template>
  <router-link
    v-if="route"
    :to="route"
    :class="[hasSlot('icon') ? 'button  has-icon' : 'button', type]"
  >
    <span class="button-icon" v-if="hasSlot('icon')">
      <slot v-if="hasSlot('icon')" name="icon" />
    </span>
    <p class="button-text" v-if="text">{{ text }}</p>
  </router-link>
  <button
    v-else
    :class="[hasSlot('icon') ? 'button  has-icon' : 'button', type]"
    type="button"
  >
    <span class="button-icon" v-if="hasSlot('icon')">
      <slot v-if="hasSlot('icon')" name="icon" />
    </span>
    <p class="button-text" v-if="text">{{ text }}</p>
  </button>
</template>

<script>
import { computed } from "vue";

export default {
  name: "KsButton",
  props: {
    text: {
      type: String,
      default: "",
    },
    route: {
      type: Object,
      required: false,
    },

    type: {
      type: String,
      default: "primary",
      validator(value) {
        return ["secondary"].includes(value);
      },
    },
  },

  setup(props, { slots }) {
    const hasSlot = computed(() => {
      return (slot) => (slots[slot] ? true : false);
    });

    return {
      hasSlot,
    };
  },
};
</script>

<style lang="scss">
@import "style.scss";
</style>