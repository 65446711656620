<template>
  <div class="search-box">
    <div class="icon-box">
      <img class="icons" src="@/assets/images/icons/search.svg" />
    </div>
    <div class="icon-box close" @click="$emit('reset')" v-if="reset">
      <img class="icons" src="@/assets/images/icons/close.svg" />
    </div>
    <slot name="action">
      <input />
    </slot>
  </div>
</template>

<script>
export default {
  name: "KsSearchBox",
  props: {
    reset: Boolean,
  },

  setup() {
    return {};
  },
};
</script>


<style lang="scss">
@import "style.scss";
</style>