<template>
  <div
    :ref="
      (el) => {
        mainElement = el;
      }
    "
    class="select-list-item"
  >
    <div
      class="select-list"
      :data-value="selectListTitle"
      :data-list="options"
      :class="{ focused: focused, danger: data.errors.length > 0 }"
    >
      <div class="selector" @click="toggle()">
        <div class="label" :class="{ active: ActiveClass }">
          {{ selectListTitle }}
        </div>
        <div class="arrow" :class="{ expanded: visible }">
          <img class="icons" src="@/assets/images/icons/arrow.svg" />
        </div>
        <transition name="slide2">
          <ul
            class="dropdown-items"
            v-if="visible"
            :class="{ active: options.length > 6 }"
          >
            <li
              class="dropdown-list"
              :class="{ current: option.title === selectListTitle }"
              v-for="(option, index) of options"
              :key="index"
              @click="onUpdateValue(option)"
            >
              <span class="dropdown-item">
                {{ option.title }}
              </span>
            </li>
          </ul>
        </transition>
      </div>
    </div>

    <div class="validations" v-if="data.errors.length > 0">
      <span
        class="form-error-text text-danger"
        v-for="error in data.errors"
        :key="error"
      >
        {{ error }}
      </span>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { computed } from "@vue/runtime-core";

export default {
  name: "KsSelectList",
  props: {
    buttonTitle: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    data: {
      type: Object,
      default: () => {
        return { value: "", errors: [] };
      },
      validator: (value) => {
        return (
          "value" in value && "errors" in value && Array.isArray(value.errors)
        );
      },
    },
  },

  emits: ["updateValue"],
  setup(props, { emit }) {
    const visible = ref(false);
    const ActiveClass = ref(false);
    const mainElement = ref(null);
    const selectListTitle = computed(() => {
      if (props.data.value) {
        const index = props.options.findIndex(
          (o) => o.value === props.data.value
        );
        ActiveClass.value = true;
        if (index > -1) {
          return props.options[index].title;
        }
      }

      return props.buttonTitle;
    });

    function toggle() {
      visible.value = !visible.value;
    }
    const onUpdateValue = (option) => {
      visible.value = true;
      ActiveClass.value = true;
      selectListTitle.value = option.title;
      emit("updateValue", option.value);
    };

    document.addEventListener("click", (e) => {
      if (visible.value) {
        const isEqual = e.target === mainElement.value;

        if (isEqual) return false;

        if (e.target.parentElement) {
          let parentElement = e.target.parentElement;

          let isBreaked = false;
          while (parentElement && mainElement.value !== parentElement) {
            if (mainElement.value === parentElement) {
              isBreaked = true;
              parentElement = null;
              break;
            } else {
              parentElement = parentElement.parentElement;
            }
          }

          if (mainElement.value === parentElement) return false;

          if (!isBreaked) {
            visible.value = false;
            return true;
          }
        }
      }
    });

    return {
      visible,
      selectListTitle,
      onUpdateValue,
      toggle,
      mainElement,
      ActiveClass,
    };
  },
};
</script>

<style lang="scss">
@import "style.scss";
</style>