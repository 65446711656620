<template>
  <div class="page-wrapper">
    <slot />
  </div>
</template>

<script>
import { computed } from "@vue/runtime-core";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default {
  name: "AuthLayout",
  setup() {
    const store = new useStore();
    const router = new useRouter();

    const isLoggedIn = computed(() => {
      return store.getters.isLoggedIn;
    });

    if (isLoggedIn.value) {
      router.push({ name: "dashboard" });
    }
    return {};
  },
};
</script>