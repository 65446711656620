import axios from "axios";

const GetMemos = (query) => axios.get(`memos${query ? "?" + query : ""}`);

const DeleteMemo = (id) => axios.delete(`memos/${id}`);

const CreateMemo = (data ) => axios.post("memos" , data);

const CreateClientMemo = (query , data ) => axios.post(`memos${query ? "?" + query : ""}` , data);

export { GetMemos , DeleteMemo , CreateMemo , CreateClientMemo};
