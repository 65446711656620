<template>
  <div :class="['tag-item', type]">
    <p class="tag-text">{{ text }}</p>
    <span class="tag-icon" v-if="hasSlot('icon')">
      <slot v-if="hasSlot('icon')" name="icon" />
    </span>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  name: "KsTag",
  props: {
    text: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
      validator(value) {
        return [""].includes(value);
      },
    },
  },

  setup(props, { slots }) {
    const hasSlot = computed(() => {
      return (slot) => (slots[slot] ? true : false);
    });

    return {
      hasSlot,
    };
  },
};
</script>

<style lang="scss">
@import "style.scss";
</style>