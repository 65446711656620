<template>
  <div class="header" v-if="header">
    <div class="title">
      <img src="@/assets/images/icons/title.svg" />
    </div>
    <div class="log-out" @click="logOut">
      <div class="icon-box">
        <img class="icon" src="@/assets/images/icons/logout.png" />
      </div>
      <div class="text">خروج از حساب</div>
    </div>
  </div>
  <div class="menu">
    <div class="logo-box">
      <img src="@/assets/images/icons/logo.svg" />
    </div>
    <router-link active-class="active" class="menu-item" to="/">
      <img class="icon" src="@/assets/images/icons/home.svg" />
      <img class="icon-hover" src="@/assets/images/icons/home-hover.svg" />
    </router-link>
    <router-link active-class="active" class="menu-item middel" to="/chats">
      <img class="icon" src="@/assets/images/icons/template.svg" />
      <img class="icon-hover" src="@/assets/images/icons/template-hover.svg" />
    </router-link>
    <router-link active-class="active" class="menu-item" to="/template">
      <img class="icon" src="@/assets/images/icons/message.svg" />
      <img class="icon-hover" src="@/assets/images/icons/message-hover.svg" />
    </router-link>
    <div class="log-out" @click="logOut">
      <div class="icon-box">
        <img class="icon" src="@/assets/images/icons/logout.png" />
      </div>
      <div class="text">خروج از حساب</div>
    </div>
  </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { notify } from "@kyvg/vue3-notification";
import { computed } from '@vue/runtime-core';

export default {
  name: "KsMenu",

  setup() {
    const store = new useStore();
    const router = new useRouter();
    const route = new useRoute();

    const header = computed(() => {
      return route.meta.header;
    });

    const logOut = async () => {
      store.dispatch("logout");
      setTimeout(() => {
        router.push({ name: "login" });
      }, 200);
      notify({
        type: "success",
        text: "خروج از حساب با موفقیت انجام شد .",
      });
    };

    return {
      logOut,
      header,
    };
  },
};
</script>
<style lang="scss">
@import "style.scss";
</style>