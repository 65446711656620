<template>
  <div class="pagination">
    <!-- prev page -->
    <span
      v-if="
        pagination.page_id === '1' ||
        pagination.page_id === pagination.total_pages
      "
      class="pagination__item pagination__action pagination__item--disabled"
    >
      <svg
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.9997 16.9998L8.99969 11.9998L13.9997 6.99977"
          stroke="#706A68"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="stroke"
        />
      </svg>
    </span>
    <router-link
      v-else
      :to="
        generateRoute({
          page_id: pagination.page_id - 1,
          page_size: pagination.page_size,
        })
      "
      class="pagination__item pagination__action"
    >
      <svg
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.9997 16.9998L8.99969 11.9998L13.9997 6.99977"
          stroke="#706A68"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
           class="stroke"
        />
      </svg>
    </router-link>

    <!-- page button -->
    <template v-for="index in pagination.total_pages" :key="index">
      <span
        v-if="pagination.page_id == index"
        class="pagination__item pagination__item--active"
      >
        {{ index }}
      </span>
      <router-link
        v-else
        :to="
          generateRoute({
            page_id: index,
            page_size: pagination.page_size,
          })
        "
        class="pagination__item"
      >
        {{ index }}
      </router-link>
    </template>

    <!-- next page -->
    <span
      v-if="pagination.page_id == pagination.total_pages"
      class="pagination__item pagination__action pagination__item--disabled"
    >
      <svg
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.84399 6.84601L14.844 11.846L9.84399 16.846"
          stroke="#706A68"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
           class="stroke"
        />
      </svg>
    </span>
    <router-link
      v-else
      :to="
        generateRoute({
          page_id: parseInt(pagination.page_id) + 1,
          page_size: pagination.page_size,
        })
      "
      class="pagination__item pagination__action"
    >
      <svg
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.84399 6.84601L14.844 11.846L9.84399 16.846"
          stroke="#706A68"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
           class="stroke"
        />
      </svg>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "KsPagination",
  props: {
    pagination: {
      type: Object,
      default: () => {
        return {
          page_id: 1,
          page_size: 5,
          total_count: 1,
          total_pages: 1,
        };
      },
      required: true,
      validator: (value) => {
        return (
          "page_id" in value &&
          "page_size" in value &&
          "total_count" in value &&
          "total_pages" in value
        );
      },
    },
    route: {
      type: Object,
      required: true,
    },
    
  },
  setup(props) {
    const generateRoute = (query) => {
      const _route = {
        name: props.route.name,
        query: {
          ...props.route.query,
        },
      };
      _route.query["page"] = query.page_id;
      _route.query["pagination"] = query.page_size;
      return _route;
    };
   

    return {
      generateRoute,
    };
  },
};
</script>

<style lang="scss">
@import "./style.scss";
</style>
