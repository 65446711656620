<template>
  <KsPageLoading />
  <div class="template-page" v-if="!pageLoading">
    <KsPopup :showPopup="sendTemplates" @close="close">
      <template #body>
        <transition name="fade" v-if="createTemplateSuccess" appear>
          <div class="deleteSuccses">
            <div class="icon-box">
              <img class="icon" src="@/assets/images/icons/template-done.svg" />
            </div>
            <div class="text">پیام برای کارو استودیو ثبت شد</div>
          </div>
        </transition>
        <form class="form" v-else>
          <KsSelectList
            buttonTitle="سرویس"
            :options="services"
            :data="form.items.service_id"
            @updateValue="(data) => (form.items.service_id.value = data)"
          />
          <KsTextArea
            placeholder="متن پیام آمده"
            :data="form.items.content"
            @updateValue="(data) => (form.items.content.value = data)"
          />
          <div class="button-row">
            <KsLoader v-if="loading" />
            <KsButton type="send" v-else @click="submit">
              <template #icon>
                <img src="@/assets/images/icons/send.svg" />
              </template>
            </KsButton>
          </div>
        </form>
      </template>
    </KsPopup>
    <KsPopup :showPopup="deleteTemplatePopup" @close="close">
      <template #body>
        <div class="content">
          <transition name="fade" v-if="deleteTemplateSuccess" appear>
            <div class="deleteSuccses">
              <div class="icon-box">
                <img class="icon" src="@/assets/images/icons/done.svg" />
              </div>
              <div class="text">رفت که بره برنگرده</div>
            </div>
          </transition>
          <div v-else>
            <KsMessage :text="template.content" />
            <div class="notice-text">
              مطمئنی حذفش کنم؟ تایید کن بره دیگه برنگرده
            </div>
            <KsButton text="تایید و حذف" type="delete" @click="deleteTemplate">
            </KsButton>
          </div>
        </div>
      </template>
    </KsPopup>
    <KsButton type="blue" text="ثبت پیام جدید" @click="openSendTemplates">
    </KsButton>

    <masonry-wall
      :items="templates"
      :gap="14"
      :rtl="false"
      :ssr-columns="20"
      :column-width="292"
    >
      <template #default="{ item }">
        <KsTemplate :item="item">
          <template #details>
            <div class="details">
              <KsTag
                type=" new"
                text="حذف"
                @click="handelDeleteTemplate(item.id, item.content)"
              />
              <KsTag type="has-icon " :text="item.service">
                <template #icon>
                  <img src="@/assets/images/icons/tag.svg" />
                </template>
              </KsTag>
            </div>
          </template>
        </KsTemplate>
      </template>
    </masonry-wall>
  </div>
</template>

<script>
import { onMounted, reactive, ref, watch } from "@vue/runtime-core";
import {
  GetTemplates,
  DeleteTemplate,
  CreateTemplate,
} from "@/api/template.api";
import { GetServices } from "@/api/service.api";
import { notify } from "@kyvg/vue3-notification";

export default {
  name: "TemplatePage",
  setup() {
    const templates = ref([]);
    const sendTemplates = ref(false);
    const deleteTemplatePopup = ref(false);
    const deleteTemplateSuccess = ref(false);
    const createTemplateSuccess = ref(false);
    const services = ref([]);
    const loading = ref(false);
    const pageLoading = ref(true);
    const form = reactive({
      disabled: true,
      items: {
        service_id: {
          value: "",
          errors: [],
        },
        content: {
          value: "",
          errors: [],
        },
      },
    });
    const template = reactive({
      content: "",
      id: "",
    });

    const close = async () => {
      sendTemplates.value = false;
      deleteTemplatePopup.value = false;
      form.items.content.value = "";
      form.items.service_id.value = "";
    };

    const handelDeleteTemplate = async (id, content) => {
      deleteTemplateSuccess.value = false;
      deleteTemplatePopup.value = true;
      template.id = id;
      template.content = content;
      sendTemplates.value = false;
    };

    const openSendTemplates = async () => {
      createTemplateSuccess.value = false;
      deleteTemplatePopup.value = false;
      sendTemplates.value = true;
    };

    const deleteTemplate = async () => {
      const response = await DeleteTemplate(template.id);
      if (response.data.success) {
        deleteTemplateSuccess.value = true;
        setTimeout(() => {
          close();
          getTemplates();
          notify({
            type: "success",
            text: response.data.message,
          });
        }, 1000);
      } else if (response.data.status !== 401 && response.data.message) {
        notify({
          type: "error",
          text: response.data.message,
        });
      }
    };

    const getTemplates = async () => {
      const response = await GetTemplates();
      if (response.data.success) {
        templates.value = response.data.data;
        pageLoading.value = false;
      } else if (response.data.status !== 401 && response.data.message) {
        notify({
          type: "error",
          text: response.data.message,
        });
      }
    };

    const getServices = async () => {
      const response = await GetServices();
      if (response.data.success) {
        services.value = response.data.data.map((i) => {
          return {
            value: i.id,
            title: i.name,
          };
        });
        console.log(services.value);
      } else if (response.data.status !== 401 && response.data.message) {
        notify({
          type: "error",
          text: response.data.message,
        });
      }
    };

    const createTemplate = async () => {
      const response = await CreateTemplate({
        content: form.items.content.value,
        service_id: form.items.service_id.value,
      });
      if (response.data.success) {
        loading.value = false;
        createTemplateSuccess.value = true;

        setTimeout(() => {
          close();
          getTemplates();
          notify({
            type: "success",
            text: response.data.message,
          });
          form.items.content.value = "";
          form.items.service_id.value = "";
        }, 1500);
      } else if (response.data.status !== 401 && response.data.message) {
        loading.value = false;
        notify({
          type: "error",
          text: response.data.message,
        });
      }
      loading.value = false;
      form.disabled = false;
    };

    const validate = () => {
      let isValid = true;
      loading.value = true;
      for (const item in form.items) {
        form.items[item].errors = [];
      }
      if (!form.items.content.value) {
        isValid = false;
        loading.value = false;
        form.items.content.errors.push("فیلد خالی است .");
      }
      if (!form.items.service_id.value) {
        isValid = false;
        loading.value = false;
        form.items.service_id.errors.push("سرویس را انتخاب کنید.");
      }
      return isValid;
    };

    const submit = async () => {
      if (!validate()) return false;
      form.disabled = true;
      setTimeout(() => {
        createTemplate();
      }, 1000);
    };

    onMounted(() => {
      getTemplates();
      getServices();
    });

    watch(() => {
      if (pageLoading.value) {
        document.querySelector(".karo-wrapper").classList.remove("isLoading");
      }
    });

    return {
      templates,
      sendTemplates,
      close,
      deleteTemplate,
      handelDeleteTemplate,
      template,
      deleteTemplatePopup,
      deleteTemplateSuccess,
      services,
      form,
      openSendTemplates,
      loading,
      submit,
      createTemplateSuccess,
      pageLoading,
    };
  },
};
</script>

<style lang="scss">
@import "style.scss";
</style>