<template>
  <div class="pulseLoader"></div>
</template>

<script>
export default {
  name: "KsLoader",

  setup() {
    return {};
  },
};
</script>

<style lang="scss">
@import "style.scss";
</style>