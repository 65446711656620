import { createApp } from "vue";
import App from "./App.vue";
import Notifications from "@kyvg/vue3-notification";
import router from "./router";
import MasonryWall from "@yeger/vue-masonry-wall";
import store from "./store/index.js";
import { storage } from "@/services";
import "@/services/AxiosSetup";

const app = createApp(App);

const access_token = storage.get("access_token") || "";
if (access_token !== "") store.dispatch("set_access_token", access_token);

// Global components
import Menu from "./components/Menu";
import Tag from "./components/Tag";
import ChatRow from "./components/ChatRow";
import Template from "./components/Template";
import TextArea from "./components/TextArea";
import TextBox from "./components/TextBox";
import Button from "./components/Button";
import Loader from "./components/Loader";
import SearchBox from "./components/SearchBox";
import Popup from "./components/Popup";
import Message from "./components/Message";
import SelectList from "./components/SelectList";
import Pagination from "./components/Pagination";
import PageLoading from "./components/PageLoading";

import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

app.component(Menu.name, Menu);
app.component(Tag.name, Tag);
app.component(ChatRow.name, ChatRow);
app.component(Template.name, Template);
app.component(TextArea.name, TextArea);
app.component(TextBox.name, TextBox);
app.component(Button.name, Button);
app.component(Loader.name, Loader);
app.component(Message.name, Message);
app.component(SearchBox.name, SearchBox);
app.component(Popup.name, Popup);
app.component(SelectList.name, SelectList);
app.component(Pagination.name, Pagination);
app.component(PageLoading.name, PageLoading);

app.component("AuthLayout", AuthLayout);
app.component("DashboardLayout", DashboardLayout);

app.use(Notifications);
app.use(router).use(store).use(MasonryWall).mount("#app");
