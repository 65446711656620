<template>
  <div class="login-page">
    <img class="man" src="@/assets/images/man.png" />
    <img class="cloud" src="@/assets/images/cloud.png" />
    <img class="bird" src="@/assets/images/bird.png" />
    <img class="top" src="@/assets/images/top.png" />
    <img class="bottom" src="@/assets/images/bottom.png" />
    <img class="center" src="@/assets/images/center.png" />
    <img class="purple" src="@/assets/images/purple.png" />

    <div class="login-box">
      <div class="logo-box">
        <img class="icon" src="@/assets/images/icons/login-logo.svg" />
      </div>
      <div class="title-item">
        <img class="icon" src="@/assets/images/icons/title.svg" />
      </div>
      <form>
        <KsTextBox
          placeholder="ایمیل شما"
          type="text"
          :data="form.items.email"
          @updateValue="(data) => (form.items.email.value = data)"
        >
          <template #icon>
            <img class="icon" src="@/assets/images/icons/email.png" />
          </template>
        </KsTextBox>
        <KsTextBox
          placeholder="رمز عبور "
          type="password"
          :data="form.items.password"
          @updateValue="(data) => (form.items.password.value = data)"
        >
          <template #icon>
            <img class="icon" src="@/assets/images/icons/password.svg" />
          </template>
        </KsTextBox>
        <KsButton text="ورود به حساب کاربری" type="dark" @click="submit">
          <template #icon>
            <img class="icon" src="@/assets/images/icons/bottom-arrow.svg" />
          </template>
        </KsButton>
      </form>
    </div>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { LogIn } from "@/api/login.api";
import { notify } from "@kyvg/vue3-notification";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default {
  name: "LoginPage",
  setup() {
    const store = new useStore();
    const router = new useRouter();
    const form = reactive({
      disabled: true,
      items: {
        email: {
          value: "",
          errors: [],
        },
        password: {
          value: "",
          errors: [],
        },
      },
    });

    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const validate = () => {
      let isValid = true;
      for (const item in form.items) {
        form.items[item].errors = [];
      }
      if (!form.items.email.value) {
        isValid = false;
        form.items.email.errors.push("فیلد خالی است .");
      } else if (!validateEmail(form.items.email.value)) {
        isValid = false;
        form.items.email.errors.push("ایمیل معتبر نیست .");
      }
      if (!form.items.password.value) {
        isValid = false;
        form.items.password.errors.push("فیلد خالی است .");
      } else if (form.items.password.value.length < 8) {
        isValid = false;
        form.items.password.errors.push(
          "پسورد باید حداقل دارای ۸ کاراکتر باشد "
        );
      }
      return isValid;
    };

    const logIn = async () => {
      const response = await LogIn({
        email: form.items.email.value,
        password: form.items.password.value,
      });

      if (response.data.success) {
        notify({
          type: "success",
          text: "به داشبورد خوش آمدید",
        });
        store.dispatch("set_access_token", response.data.data.token);
        setTimeout(() => {
          router.push({ name: "dashboard" });
        }, 200);
      } else if (response.data.status !== 401 && response.data.message) {
        notify({
          type: "error",
          text: response.data.message,
        });
      }
      form.disabled = false;
    };

    const submit = async () => {
      if (!validate()) return false;
      console.log("mahsa");

      logIn();
    };

    return { form, submit };
  },
};
</script>

<style lang="scss">
@import "style.scss";
</style>