<template>
  <transition name="slide" mode="out-in" v-show="showPopup">
    <div class="popup" :class="[showPopup ? 'popup  active' : 'popup', type]">
      <div class="popup-container">
        <div class="popup-header">
          <div class="close" @click="$emit('close')">
            <img class="icon" src="@/assets/images/icons/close.svg" />
          </div>
          <slot name="header"></slot>
        </div>
        <div
          class="popup-body"
          :id="id"
          :class="[hasSlot('footer') ? 'popup-body min-height' : 'popup-body']"
        >
          <slot name="body"> </slot>
        </div>
        <div class="popup-footer" v-if="hasSlot('footer')">
          <slot name="footer"> </slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { computed } from "@vue/runtime-core";
export default {
  name: "KsPopup",
  props: {
    showPopup: {
      type: Boolean,
    },
    index: {
      type: String,
    },
    id: {
      type: String,
    },

    type: {
      type: String,
      default: "",
      validator(value) {
        return [""].includes(value);
      },
    },
  },
  setup(props, { slots }) {
    const hasSlot = computed(() => {
      return (slot) => (slots[slot] ? true : false);
    });

    return {
      hasSlot,
    };
  },
};
</script>

<style lang="scss">
@import "style.scss";
</style>