import axios from "axios";

const GetTemplates = () => axios.get(`templates`);

const DeleteTemplate = (id) => axios.delete(`templates/${id}`);

const CreateTemplate = (data) => axios.post("templates" , data);

const GetTemplatesById = (id) => axios.get(`templates?service_id=${id}`)

export { GetTemplates , DeleteTemplate , CreateTemplate , GetTemplatesById};
