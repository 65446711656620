const prefix = "ks.";

const get = (key) => {
  let value = localStorage.getItem(prefix + key);

  try {
    value = JSON.parse(value);
  } catch (error) {
    // Value is not a valid JSON string.
  }

  return value;
};

const set = (key, value) => {
  if (typeof value === "object") {
    value = JSON.stringify(value);
  }

  localStorage.setItem(prefix + key, value);
};

const clear = () => {
  localStorage.clear();
};

export default {
  prefix,
  get,
  set,
  clear,
};
