<template>
  <div class="progress-materializecss">
    <div class="indeterminate"></div>
  </div>
</template>
<script>
export default {
  name: "KsPageLoading",

  setup() {
    return {};
  },
};
</script>
<style lang="scss">
@import "style.scss";
</style>