<template>
  <div class="template-item">
    <p class="template-text">
      {{ item.content }}
    </p>
    <slot name="details"></slot>
  </div>
</template>

<script>
export default {
  name: "KsTemplate",
  props: {
    item: {
      type: Object,
      default: () => {
        return {
          value: "",
          errors: [],
        };
      },
    },
  },

  setup() {
    return {};
  },
};
</script>

<style lang="scss">
@import "style.scss";
</style>