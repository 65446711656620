<template>
  <div :class="['message-item', type]">
    <div
      class="message-title"
      v-if="title"
      :style="{ color: color }"
      @click="$emit('select')"
    >
      {{ title }}
    </div>
    <div class="message-box">
      <div class="message-text" v-if="text">{{ text }}</div>
      <div class="data" v-if="date">{{ date }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "KsMessage",
  props: {
    text: {
      type: String,
    },
    date: {
      type: String,
    },
    title: {
      type: String,
    },
    color: {
      type: String,
    },
    type: {
      type: String,
      default: "answer",
      validator(value) {
        return [" question"].includes(value);
      },
    },
  },
};
</script>

<style lang="scss">
@import "style.scss";
</style>