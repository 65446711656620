<template>
  <div class="karo-wrapper">
    <notifications position="center" classes="my-notification" />

    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import { computed } from "@vue/runtime-core";
import { useRoute } from "vue-router";
export default {
  name: "App",
  setup() {
    const route = new useRoute();
    const layout = computed(() => {
      return route.meta.layout + "Layout";
    });

    return {
      layout,
    };
  },
};
</script>

<style lang="scss">
@import "./assets/scss/main.scss";
</style>
