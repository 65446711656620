<template>
  <div class="form-control-row">
    <label class="form-label" v-if="title">{{ title }}</label>
    <div class="input-row">
      <div class="icon-box" v-if="hasSlot('icon')">
        <slot name="icon" />
      </div>
      <textarea
        type="text"
        :class="[
          hasSlot('icon')
            ? 'form-textarea form-control  input-icon'
            : 'form-control form-textarea',
        ]"
        :placeholder="placeholder"
        v-model.lazy.trim="value"
        @focus="resize"
        @keydown="resize"
        ref="textarea"
        autocomplete="off"
        :id="id"
      ></textarea>
    </div>

    <div class="validations" v-if="data.errors.length > 0">
      <span
        class="form-error-text text-danger"
        v-for="error in data.errors"
        :key="error"
      >
        {{ error }}
      </span>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
export default {
  name: "KsTextArea",
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    data: {
      type: Object,
      default: () => {
        return { value: "", errors: [] };
      },
      validator: (value) => {
        return (
          "value" in value && "errors" in value && Array.isArray(value.errors)
        );
      },
    },
  },
  methods: {
    resize(event) {
      const { textarea } = this.$refs;
      textarea.style.height = 67 + "px";
      textarea.style.height = textarea.scrollHeight - 2 + "px";
      if (document.querySelector(".popup-body.min-height") != null) {
        const mahsa = document.querySelector(".popup-body.min-height");
        const height = textarea.scrollHeight + 0 + 13 + 78 + 28 + 20 + 7;
        mahsa.style.height = `calc(100% - ${height}px)`;
      }

      if (event.keyCode == 13) {
        event.preventDefault();
      }
    },
  },
  // mounted() {
  //   this.resize();
  // },

  data() {
    return {
      value: this.data.value,
    };
  },

  emits: ["updateValue"],
  setup(props, { slots }) {
    const hasSlot = computed(() => {
      return (slot) => (slots[slot] ? true : false);
    });

    return {
      hasSlot,
    };
  },

  watch: {
    value(newValue) {
      this.$emit("updateValue", newValue);
    },
    data: {
      handler(newValue) {
        this.value = newValue.value;
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
@import "style.scss";
</style>