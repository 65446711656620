import { createStore } from "vuex";
import { storage } from "@/services";

const store = createStore({
  state: {
    access_token: null,
  },
  getters: {
    isLoggedIn(state) {
      return state.access_token ? true : false;
    },
  },
  mutations: {
    SET_ACCESS_TOKEN(state, token) {
      state.access_token = token;
    },
    CLEAR_ACCESS_TOKEN(state) {
      state.access_token = null;
    },
  },

  actions: {
    set_access_token({ commit }, accessToken) {
      if (accessToken) storage.set("access_token", accessToken);
      commit("SET_ACCESS_TOKEN", accessToken);
    },
    logout({ commit }) {
      storage.clear();
      commit("CLEAR_ACCESS_TOKEN");
    },
  },
});

export default store;
