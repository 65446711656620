<template>
  <div class="chart-row">
    <div class="title-item user-name">{{ chat.name }}</div>
    <div class="tooltipe">
      <div class="text">{{ chat.last_message.content }}</div>
    </div>
    <div class="tag-row">
      <KsTag type="has-icon " :text="chat.date">
        <template #icon>
          <img src="@/assets/images/icons/clock.svg" />
        </template>
      </KsTag>
      <KsTag text="بسته شده" v-if="chat.open == false" />
      <KsTag
        type=" dark"
        text="پاسخ داده شده"
        v-if="(chat.open == true) & (chat.status == 3)"
      />
      <KsTag
        type="wait-answer"
        text="منتظر پاسخ "
        v-if="(chat.open == true) & (chat.status == 2)"
      />
      <KsTag
        type=" new"
        text="جدید"
        v-if="(chat.open == true) & (chat.status == 1)"
      />
      <KsTag
        type="has-icon dark"
        :text="chat.client.name"
        v-if="chat.client != null"
      >
        <template #icon>
          <img src="@/assets/images/icons/asign.svg" />
        </template>
      </KsTag>
      <KsTag type="has-icon " :text="chat.service.name">
        <template #icon>
          <img src="@/assets/images/icons/tag.svg" />
        </template>
      </KsTag>
      <KsTag
        type="color-tag"
        v-if="chat.color != null"
        :style="{ backgroundColor: chat.color.code }"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "KsChatRow",
  props: {
    chat: {
      type: Object,
    },
  },
  setup() {
    return {};
  },
};
</script>
<style lang="scss">
@import "style.scss";
</style>