<template>
  <div class="form-control-row">
    <label class="form-label" v-if="title">{{ title }}</label>
    <div class="input-row">
      <div class="icon-box" v-if="hasSlot('icon')">
        <slot name="icon" />
      </div>
      <input
        :type="type"
        :class="[
          hasSlot('icon') ? ' form-control  input-icon' : 'form-control ',
        ]"
        :placeholder="placeholder"
        v-model.lazy.trim="value"
        autocomplete="off"
      />
    </div>

    <div class="validations" v-if="data.errors.length > 0">
      <span
        class="form-error-text text-danger"
        v-for="error in data.errors"
        :key="error"
      >
        {{ error }}
      </span>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
export default {
  name: "KsTextBox",
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },

    data: {
      type: Object,
      default: () => {
        return { value: "", errors: [] };
      },
      validator: (value) => {
        return (
          "value" in value && "errors" in value && Array.isArray(value.errors)
        );
      },
    },
  },

  data() {
    return {
      value: this.data.value,
    };
  },
  emits: ["updateValue"],
  setup(props, { slots }) {
    const hasSlot = computed(() => {
      return (slot) => (slots[slot] ? true : false);
    });

    return {
      hasSlot,
    };
  },

  watch: {
    value(newValue) {
      this.$emit("updateValue", newValue);
    },
    data: {
      handler(newValue) {
        this.value = newValue.value;
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
@import "style.scss";
</style>