import axios from "axios";

const GetMenus = () => axios.get(`dashbaord`);

const GetChats = (query) => axios.get(`chats${query ? "?" + query : ""}`);

const GetMyChats = () => axios.get(`chats?assigned_to_me=1`);

const ChatEdit = (id, data) => axios.put(`chats/${id}`, data);

const GetClients = () => axios.get(`clients/admins`);

const GetColors = () => axios.get(`colors`);

export { GetMenus, GetChats, GetMyChats, ChatEdit, GetClients, GetColors };
