import { createWebHistory, createRouter } from "vue-router";

import Dashboard from "./views/Dashboard";
import Chat from "./views/Chat";
import Template from "./views/Template";
import Login from "./views/Login";

const routes = [
  {
    path: "/",
    name: "dashboard",
    component: Dashboard,
    meta: { layout: "Dashboard", header: true },
  },
  {
    path: "/chats",
    name: "chats",
    component: Chat,
    meta: { layout: "Dashboard", header: false },
  },
  {
    path: "/template",
    name: "template",
    component: Template,
    meta: { layout: "Dashboard", header: true },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { layout: "Auth" },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active",
});
console.log(router)

router.beforeEach((to, from, next) => {
  document.querySelector(".karo-wrapper").classList.add("isLoading");
  to;
  from;
  setTimeout(() => {
    next();
  }, 1000);
});

export default router;
