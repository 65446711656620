import axios from "axios";
import { storage } from '@/services'

class AxiosSetup {
  constructor() {
    this.setDefaults();
    this.setInterceptors();
  }

  setDefaults = () => {
    axios.defaults.timeout = 30 * 1000;
    axios.defaults.baseURL = process.env.VUE_APP_BASE_URL || "/";
  };

  setInterceptors = () => {
    axios.interceptors.request.use(async (config) => {
      const token = storage.get("access_token") || "";
      config.headers["Authorization"] = `Bearer ${token}`;
      return {
        ...config,
        params: { ...config.params },
      };
    });
  };
}

const instance = new AxiosSetup();

export default instance;
