<template>
  <div class="page-wrapper">
    <KsMenu />
    <div class="content-wrapper">
      <router-view> </router-view>
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "DashboardLayout",
  setup() {
    const store = new useStore();
    const router = new useRouter();

    const isLoggedIn = computed(() => {
      return store.getters.isLoggedIn;
    });

    if (!isLoggedIn.value) {
      router.push({ name: "login" });
    }

    return {
    };
  },
};
</script>


<style  lang="scss">
</style>
