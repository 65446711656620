<template>
  <KsPageLoading />
  <div class="home-page" v-if="!pageLoading">
    <KsPopup
      :showPopup="showChat"
      @close="close('showChat')"
      :index="10"
      type="chat main"
      id="scroll-box"
    >
      <template #header>
        <div class="title">{{ chat.name.value }}</div>
        <div class="text">{{ chat.service.name }}</div>
      </template>
      <template #body>
        <div v-for="(message, index) in messages" :key="index">
          <div v-if="message.first_message_of_the_day == true" class="date-row">
            {{ message.first }}
          </div>
          <KsMessage
            v-if="message.client_id.is_admin == 1"
            :date="message.created_at"
            :title="
              (messages[index > 0 ? index - 1 : 0].client_id.id ==
                message.client_id.id) &
              !message.first_message_of_the_day
                ? ''
                : message.client_id.name
            "
            :text="message.content"
            :color="message.color"
          />
          <KsMessage
            v-else
            :date="message.created_at"
            :title="message.client_id.name"
            :text="message.content"
            type="question"
            color="#323232"
            @select="selectUser(message.client_id)"
          />
        </div>
      </template>
      <template #footer>
        <div class="action-row">
          <div class="action-item" @click="showTemplates = true">
            <img src="@/assets/images/icons/messages.svg" />
          </div>
          <div class="action-item" @click="showColors = true">
            <div class="text" v-if="chat.color_id.value == ''">تگ رنگ</div>
            <div
              class="color-box"
              v-else
              :style="{ backgroundColor: chat.color_id.code }"
            ></div>
          </div>
          <div
            class="action-item"
            @click="lockMessage"
            :class="{ active: !chat.open.value }"
          >
            <img class="lock" src="@/assets/images/icons/lock.svg" />
            <img src="@/assets/images/icons/unlock.svg" />
          </div>
          <div
            class="action-item"
            :class="{ active: chat.client_id.value != '' }"
            @click="showClients = true"
          >
            <img
              v-if="chat.client_id.value == ''"
              src="@/assets/images/icons/mention.svg"
            />
            <div class="assign" v-else>
              <img src="@/assets/images/icons/asign.svg" />
              <div class="assign-name">{{ chat.client_id.name }}</div>
            </div>
          </div>
        </div>
        <div class="send-message-row">
          <form @keyup.enter="messageSumbit">
            <KsTextArea
              placeholder=" پیام شما"
              :data="form.items.content"
              @updateValue="(data) => (form.items.content.value = data)"
              id="mainText"
            />
            <div class="button-row main-button-row">
              <KsLoader v-if="messageLoading" />
              <KsButton type="send" v-else @click="messageSumbit">
                <template #icon>
                  <img src="@/assets/images/icons/send.svg" />
                </template>
              </KsButton>
            </div>
          </form>
        </div>
      </template>
    </KsPopup>
    <KsPopup
      :showPopup="showColors"
      @close="close('showColors')"
      :index="11"
      type="chat"
    >
      <template #header>
        <div class="title">ویژه سازی شخصی</div>
      </template>
      <template #body>
        <ul class="popup-list">
          <li
            class="popup-item"
            v-for="(item, index) in colors"
            :key="index"
            @click="selectColor(item)"
          >
            <span class="color-box" :style="{ borderColor: item.code }"> </span>
            <span class="text"> {{ item.name }}</span>
          </li>
        </ul>
      </template>
    </KsPopup>
    <KsPopup
      :showPopup="showClients"
      @close="close('showClients')"
      :index="12"
      type="chat"
    >
      <template #header>
        <div class="title">سپردن به</div>
      </template>
      <template #body>
        <ul class="popup-list">
          <li
            class="popup-item client-item"
            v-for="(item, index) in clients"
            :key="index"
            @click="selectClient(item)"
          >
            <img src="@/assets/images/icons/mention.svg" />
            <span class="text"> {{ item.name }}</span>
          </li>
        </ul>
      </template>
    </KsPopup>
    <KsPopup
      :showPopup="showTemplates"
      @close="close('showTemplates')"
      :index="13"
      type="chat"
    >
      <template #header>
        <div class="title">پیام‌های آماده</div>
      </template>
      <template #body>
        <KsTextArea
          placeholder=" افزودن پیام آماده جدید "
          :data="templateForm.items.content"
          @updateValue="(data) => (templateForm.items.content.value = data)"
        />
        <div class="button-row">
          <KsLoader v-if="loading" />
          <KsButton type="send" v-else @click="templateSubmit">
            <template #icon>
              <img src="@/assets/images/icons/send.svg" />
            </template>
          </KsButton>
        </div>
        <KsTemplate
          v-for="(item, index) in templates"
          :key="index"
          :item="item"
          @click="selectTemplate(item)"
        >
        </KsTemplate>
      </template>
    </KsPopup>
    <KsPopup
      :showPopup="showUser"
      @close="close('showUser')"
      :index="13"
      type="chat"
    >
      <template #header>
        <div class="title">اطلاعات کاربر</div>
      </template>
      <template #body>
        <div
          class="user-details"
          v-for="(item, index) in userDeta"
          :key="index"
        >
          <div class="items">
            <div>{{ item.key }}:</div>
            <div>{{ item.value }}</div>
          </div>
        </div>
        <KsTextArea
          placeholder=" یاداشت"
          :data="userForm.items.content"
          @updateValue="(data) => (userForm.items.content.value = data)"
        />
        <div class="button-row">
          <KsLoader v-if="userLoading" />
          <KsButton type="send" v-else @click="createUserMemo">
            <template #icon>
              <img src="@/assets/images/icons/send.svg" />
            </template>
          </KsButton>
        </div>
        <KsTemplate
          v-for="(item, index) in userMemos"
          :key="index"
          :item="item"
        >
        </KsTemplate>
      </template>
    </KsPopup>
    <KsPopup
      :showPopup="deleteTemplatePopup"
      @close="close('deleteTemplatePopup')"
    >
      <template #body>
        <div class="content">
          <transition name="fade" v-if="deleteTemplateSuccess" appear>
            <div class="deleteSuccses">
              <div class="icon-box">
                <img class="icon" src="@/assets/images/icons/done.svg" />
              </div>
              <div class="text">رفت که بره برنگرده</div>
            </div>
          </transition>

          <div v-else>
            <KsMessage :text="template.content" />
            <div class="notice-text">
              مطمئنی حذفش کنم؟ تایید کن بره دیگه برنگرده
            </div>
            <KsButton text="تایید و حذف" type="delete" @click="deleteMemo">
            </KsButton>
          </div>
        </div>
      </template>
    </KsPopup>
    <div class="right-box">
      <div class="title">گزارش آمار ۳۰ روز گذشته</div>
      <div class="box">
        <div :class="['select-list', openRangeSelectList ? 'active' : '']">
          <span
            class="title-item"
            @click="openRangeSelectList = !openRangeSelectList"
          >
            <span class="text">{{ ReportRange.title }}</span>
            <span class="icon">
              <img src="@/assets/images/icons/arrow.svg" />
            </span>
          </span>
          <div class="list">
            <span
              v-for="(item, index) in services"
              :key="index"
              :class="['option', ReportRange.value === item.id ? 'active' : '']"
              @click="
                openRangeSelectList = false;
                ReportRange = { title: item.name, value: item.id };
                handleUpdateReportChart();
              "
            >
              {{ item.name }}
            </span>
          </div>
        </div>
        <div class="chart-box">
          <div class="chart-inner">
            <canvas
              id="report-chart"
              style="width: 100%; height: 100%"
            ></canvas>
          </div>
        </div>
        <div class="tag-row">
          <KsTag type="main recive" text="تیکت دریافت شده" />
          <KsTag type="main answer" text=" پاسخ داده شده " />

          <KsTag type="main " text=" میانگین تایم انتظار کاربر: ۱۲ دقیقه" />
        </div>
      </div>
      <div class="title">آخرین تیکت‌های سپرده شده به من</div>
      <div class="chat-box">
        <KsChatRow
          v-for="(chat, index) in myChats"
          :key="index"
          :chat="chat"
          @click="selectChat(chat)"
        />
      </div>

      <div class="title">آخرین تیکت‌های</div>
      <div class="chat-box">
        <KsChatRow
          v-for="(chat, index) in chats.slice(0, 5)"
          :key="index"
          :chat="chat"
          @click="selectChat(chat)"
        />
      </div>
    </div>
    <div class="left-box">
      <div class="title">یاداشت‌های شخصی من</div>
      <div class="box send-message-row">
        <KsTextArea
          placeholder="متن یاداشت"
          :data="form.items.content"
          @updateValue="(data) => (form.items.content.value = data)"
        />
        <div class="button-row">
          <KsLoader v-if="memoLoading" />
          <KsButton type="send" v-else @click="submit">
            <template #icon>
              <img src="@/assets/images/icons/send.svg" />
            </template>
          </KsButton>
        </div>
      </div>
      <KsTemplate v-for="(item, index) in memos" :key="index" :item="item">
        <template #details>
          <div class="details">
            <KsTag
              type=" new"
              text="حذف"
              @click="handelDeleteTemplate(item.id, item.content)"
            />
          </div>
        </template>
      </KsTemplate>
    </div>
  </div>
</template>

<script>
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
} from "chart.js";
import { onMounted, reactive, ref, watch } from "@vue/runtime-core";
import {
  GetMemos,
  DeleteMemo,
  CreateMemo,
  CreateClientMemo,
} from "@/api/memos.api";
import { notify } from "@kyvg/vue3-notification";
import {
  GetChats,
  GetMyChats,
  ChatEdit,
  GetClients,
  GetColors,
} from "@/api/chat.api";
import { GetServices } from "@/api/service.api";
import { GetReports } from "@/api/report.api";
import { GetTemplatesById, CreateTemplate } from "@/api/template.api";
import { GetMessages, CreateMessage } from "@/api/message.api";

export default {
  name: "DashboardPage",
  setup() {
    Chart.register(
      ArcElement,
      LineElement,
      BarElement,
      PointElement,
      BarController,
      BubbleController,
      DoughnutController,
      LineController,
      PieController,
      PolarAreaController,
      RadarController,
      ScatterController,
      CategoryScale,
      LinearScale,
      LogarithmicScale,
      RadialLinearScale,
      TimeScale,
      TimeSeriesScale,
      Decimation,
      Filler,
      Legend,
      Title,
      Tooltip
    );
    const clients = ref([]);
    const colors = ref([]);
    const showChat = ref(false);
    const showColors = ref(false);
    const showClients = ref(false);
    const showTemplates = ref(false);
    const chat = reactive({
      id: {
        value: "",
      },
      name: {
        value: "",
      },
      service: {
        value: "",
        name: "",
      },
      color_id: {
        value: "",
        code: "",
      },
      client_id: {
        value: "",
        name: "",
      },
      open: {
        value: "",
      },
    });
    const loading = ref(false);
    const messageLoading = ref(false);
    const templates = ref([]);
    const templateForm = reactive({
      disabled: true,
      items: {
        service_id: {
          value: "",
          errors: [],
        },
        content: {
          value: "",
          errors: [],
        },
      },
    });
    const form = reactive({
      disabled: true,
      items: {
        chat_id: {
          value: "",
          errors: [],
        },
        content: {
          value: "",
          errors: [],
        },
      },
    });
    const userForm = reactive({
      disabled: true,
      items: {
        user_id: {
          value: "",
          errors: [],
        },
        content: {
          value: "",
          errors: [],
        },
      },
    });
    const messages = ref([]);
    var persianDate = require("persian-date");
    const user = ref([]);
    const userDeta = ref([]);
    const showUser = ref(false);
    const userMemos = ref([]);
    const userLoading = ref(false);
    const pageLoading = ref(true);
    const scales = {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    };
    // const plugins = {
    //   legend: {
    //     display: false,
    //   },
    //   title: {
    //     display: false,
    //   },
    // };
    const openRangeSelectList = ref(false);
    const memoLoading = ref(false);
    const chats = ref([]);
    const myChats = ref([]);
    const memos = ref([]);
    const services = ref([]);
    const deleteTemplatePopup = ref(false);
    const deleteTemplateSuccess = ref(false);
    const template = reactive({
      content: "",
      id: "",
    });
    const memoForm = reactive({
      disabled: true,
      items: {
        content: {
          value: "",
          errors: [],
        },
      },
    });
    var datasets = [];
    let ReportChart = null;
    const ReportChartLabels = ref([]);
    const ReportRange = ref({ title: "ببینش", value: 1 });
    const ReportChartData = {
      title: null,
      labels: null,
      data: null,
    };

    const getMemos = async () => {
      const response = await GetMemos();

      if (response.data.success) {
        memos.value = response.data.data;
      } else if (response.data.status !== 401 && response.data.message) {
        notify({
          type: "error",
          text: response.data.message,
        });
      }
    };

    const getChats = async () => {
      const response = await GetChats();
      if (response.status == 200) {
        chats.value = response.data.data;
      } else if (response.data.status !== 401 && response.data.message) {
        notify({
          type: "error",
          text: response.data.message,
        });
      }
    };

    const getMyChats = async () => {
      const response = await GetMyChats();
      if (response.status == 200) {
        myChats.value = response.data.data;
      } else if (response.data.status !== 401 && response.data.message) {
        notify({
          type: "error",
          text: response.data.message,
        });
      }
    };

    const handelDeleteTemplate = async (id, content) => {
      deleteTemplateSuccess.value = false;
      deleteTemplatePopup.value = true;
      template.id = id;
      template.content = content;
    };

    const deleteMemo = async () => {
      const response = await DeleteMemo(template.id);
      if (response.data.success) {
        deleteTemplateSuccess.value = true;
        setTimeout(() => {
          close();
          getMemos();
          notify({
            type: "success",
            text: response.data.message,
          });
        }, 1000);
      } else if (response.data.status !== 401 && response.data.message) {
        notify({
          type: "error",
          text: response.data.message,
        });
      }
    };

    const createMemo = async () => {
      const response = await CreateMemo({
        content: memoForm.items.content.value,
      });
      if (response.data.success) {
        memoLoading.value = false;
        close();
        getMemos();
        notify({
          type: "success",
          text: response.data.message,
        });
        form.items.content.value = "";
      } else if (response.data.status !== 401 && response.data.message) {
        memoLoading.value = false;
        notify({
          type: "error",
          text: response.data.message,
        });
      }
      memoLoading.value = false;
      form.disabled = false;
    };

    const validate = () => {
      let isValid = true;
      memoLoading.value = true;
      for (const item in form.items) {
        form.items[item].errors = [];
      }
      if (!form.items.content.value) {
        isValid = false;
        memoLoading.value = false;
        form.items.content.errors.push("فیلد خالی است .");
      }

      return isValid;
    };

    const submit = async () => {
      if (!validate()) return false;
      form.disabled = true;
      setTimeout(() => {
        createMemo();
      }, 1000);
    };

    const getServices = async () => {
      const response = await GetServices();
      if (response.data.success) {
        services.value = response.data.data;
      } else if (response.data.status !== 401 && response.data.message) {
        notify({
          type: "error",
          text: response.data.message,
        });
      }
    };

    const getColors = async () => {
      const response = await GetColors();
      if (response.data.success) {
        colors.value = response.data.data;
      } else if (response.data.status !== 401 && response.data.message) {
        notify({
          type: "error",
          text: response.data.message,
        });
      }
    };

    const getReport = async () => {
      const response = await GetReports(ReportRange.value.value);
      if (response.data.success) {
        ReportChartData.title = ReportRange.value.title;
        ReportChartData.labels = Object.keys(
          response.data.data[Object.keys(response.data.data)[0]]
        );
        ReportChartData.data = {};

        for (const item in response.data.data) {
          ReportChartData.data[item] = Object.values(response.data.data[item]);
        }
        pageLoading.value = false;
      } else if (response.data.status !== 401) {
        notify({
          type: "error",
          text: "Can't fetch top products data!",
        });
      }
    };

    const initializeReportChart = (title, lables, data) => {
      const context = document.querySelector("#report-chart").getContext("2d");

      ReportChartLabels.value = [];
      datasets = [];
      generateChart("تیکت دریافت شده", "#A854EA", data["new_tickets"]);
      generateChart(
        "تیکت های پاسخ داده شده",
        "#4B94FF",
        data["answered_tickets"]
      );

      ReportChart = new Chart(context, {
        type: "line",
        data: {
          labels: lables,
          datasets: datasets,
        },
        options: {
          responsive: true,
          plugins: {
            tooltip: {
              enabled: false,
              position: "nearest",
              external: tooltipHandler,
              mode: "index",
            },
            legend: {
              display: false,
            },
            title: {
              display: false,
            },
          },
          scales: scales,
        },
      });
    };

    function generateChart(title, color, data) {
      const obj = {
        label: title,
        data: data,
        backgroundColor: color,
        borderWidth: 3,
        pointRadius: 10,

        pointBorderColor: "transparent",
        pointBackgroundColor: "transparent",
        bezierCurve: false,
        borderColor: color,
        fill: false,
        tension: 0.4,
      };
      ReportChartLabels.value.push({
        title: title,
        color: color,
      });
      datasets.push(obj);
    }

    const handleUpdateReportChart = async () => {
      await getReport();
      ReportChart.data.labels = Array.from(ReportChartData.labels);
      ReportChart.data.datasets[0].title = ReportRange.value.title;
      ReportChartLabels.value = [];
      datasets = [];
      generateChart(
        "تیکت دریافت شده",
        "#A854EA",
        ReportChartData.data["new_tickets"]
      );
      generateChart(
        "تیکت های پاسخ داده شده",
        "#4B94FF",
        ReportChartData.data["answered_tickets"]
      );

      ReportChart.data.datasets = Array.from(datasets);

      ReportChart.update();
    };

    const tooltipHandler = (context) => {
      let tooltipEl = document.getElementById("chartjs-tooltip");

      if (!tooltipEl) {
        tooltipEl = document.createElement("div");
        tooltipEl.id = "chartjs-tooltip";
        tooltipEl.innerHTML =
          "<span id='tooltip-line'></span><div class='tooltip'></div>";
        document.body.appendChild(tooltipEl);
      }

      const tooltipModel = context.tooltip;
      if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
      }

      function getBody(bodyItem) {
        return bodyItem.lines;
      }

      // Set Text
      if (tooltipModel.body) {
        const bodyLines = tooltipModel.body.map(getBody);

        let innerHtml = "";

        innerHtml += "</div><div class='tooltip-body'>";

        bodyLines.forEach(function (body) {
          innerHtml += "<span>" + body + "</span>";
        });
        // console.log(bodyLines)
        innerHtml += "</div>";

        let tableRoot = tooltipEl.querySelector("div");
        tableRoot.innerHTML = innerHtml;
      }

      const position = context.chart.canvas.getBoundingClientRect();
      let tooltipLine = document.getElementById("tooltip-line");
      tooltipEl.style.opacity = 1;
      tooltipEl.style.position = "absolute";
      tooltipEl.style.left =
        position.left + window.pageXOffset + tooltipModel.caretX - 157 + "px";
      tooltipEl.style.top =
        position.top + window.pageYOffset + tooltipModel.caretY + "px";
      tooltipLine.style.bottom = tooltipModel.caretY - 105 + "px";
      tooltipEl.style.padding =
        tooltipModel.padding + "px " + tooltipModel.padding + "px";
      tooltipEl.style.pointerEvents = "none";
    };

    const getUserMemos = async (id) => {
      const response = await GetMemos(`client_id=${id}`);
      if (response.data.success) {
        userMemos.value = response.data.data;
      } else if (response.data.status !== 401 && response.data.message) {
        notify({
          type: "error",
          text: response.data.message,
        });
      }
    };

    const createUserMemo = async () => {
      const response = await CreateClientMemo(
        `client_id=${userForm.items.user_id.value}`,
        {
          content: userForm.items.content.value,
        }
      );
      if (response.data.success) {
        userLoading.value = true;
        setTimeout(() => {
          getUserMemos(user.value.id);
          userForm.items.content.value = "";
          userLoading.value = false;
        }, "1000");
      } else if (response.data.status !== 401 && response.data.message) {
        userLoading.value = false;
        notify({
          type: "error",
          text: response.data.message,
        });
      }
      form.disabled = false;
    };

    const selectUser = async (item) => {
      userDeta.value = [];
      user.value = [];
      showUser.value = true;
      user.value = item;
      userDeta.value = JSON.parse(item.payload);
      getUserMemos(item.id);
      userForm.items.user_id.value = item.id;
    };

    const getTemplates = async () => {
      const response = await GetTemplatesById(chat.service.value);
      if (response.data.success) {
        templates.value = response.data.data;
      } else if (response.data.status !== 401 && response.data.message) {
        notify({
          type: "error",
          text: response.data.message,
        });
      }
    };

    const selectChat = async (item) => {
      showUser.value = false;
      showChat.value = true;
      chat.id.value = item.id;
      form.items.chat_id.value = item.id;
      form.items.content.value = "";
      chat.open.value = item.open;
      if (item.client != null) {
        chat.client_id.value = item.client.id;
        chat.client_id.name = item.client.name;
      } else {
        chat.client_id.value = "";
        chat.client_id.name = "";
      }
      if (item.color != null) {
        chat.color_id.value = item.color.id;
        chat.color_id.code = item.color.code;
      } else {
        chat.color_id.value = "";
        chat.color_id.code = "";
      }
      if (item.service != null) {
        chat.service.value = item.service.id;
        chat.service.name = item.service.name;
        templateForm.items.service_id.value = chat.service.value;
      } else {
        chat.service.value = "";
        chat.service.name = "item.service.name;";
      }
      chat.name.value = item.name;
      getTemplates();

      getMessages();
    };

    const getClients = async () => {
      const response = await GetClients();
      if (response.data.success) {
        clients.value = response.data.data;
      } else if (response.data.status !== 401 && response.data.message) {
        notify({
          type: "error",
          text: response.data.message,
        });
      }
    };

    const chatEdit = async () => {
      let params = {
        open: chat.open.value,
      };
      if (chat.client_id.value > 0) params.client_id = chat.client_id.value;

      if (chat.color_id.value > 0) {
        params.color_id = chat.color_id.value;
      }

      const response = await ChatEdit(chat.id.value, params);
      if (response.data.success) {
        getChats();
        getMyChats();
      } else if (response.data.status !== 401 && response.data.message) {
        notify({
          type: "error",
          text: response.data.message,
        });
      }
    };

    const lockMessage = async () => {
      chat.open.value = !chat.open.value;
      chatEdit();
    };

    const selectColor = async (item) => {
      chat.color_id.value = item.id;
      chat.color_id.code = item.code;
      showColors.value = false;
      chatEdit();
    };

    const selectClient = async (item) => {
      chat.client_id.value = item.id;
      chat.client_id.name = item.name;
      showClients.value = false;
      chatEdit();
    };

    const createTemplate = async () => {
      const response = await CreateTemplate({
        content: templateForm.items.content.value,
        service_id: templateForm.items.service_id.value,
      });
      if (response.data.success) {
        loading.value = false;
        getTemplates();
        templateForm.items.content.value = "";
        templateForm.items.service_id.value = "";
      } else if (response.data.status !== 401 && response.data.message) {
        loading.value = false;
        notify({
          type: "error",
          text: response.data.message,
        });
      }
      loading.value = false;
      templateForm.disabled = false;
    };

    const createMessage = async () => {
      const response = await CreateMessage(form.items.chat_id.value, {
        content: form.items.content.value,
      });
      if (response.data.success) {
        messageLoading.value = false;
        getMessages();
        form.items.content.value = "";
        document.getElementById("mainText").value = "";
        document.getElementById("mainText").focus();
      } else if (response.data.status !== 401 && response.data.message) {
        messageLoading.value = false;
        notify({
          type: "error",
          text: response.data.message,
        });
      }
      messageLoading.value = false;
      form.disabled = false;
    };

    const templateValidate = () => {
      let isValid = true;
      loading.value = true;
      for (const item in templateForm.items) {
        templateForm.items[item].errors = [];
      }
      if (!templateForm.items.content.value) {
        isValid = false;
        loading.value = false;
        templateForm.items.content.errors.push("فیلد خالی است .");
      }
      if (!templateForm.items.service_id.value) {
        isValid = false;
        loading.value = false;
        templateForm.items.service_id.errors.push("سرویس را انتخاب کنید.");
      }
      return isValid;
    };

    const templateSubmit = async () => {
      if (!templateValidate()) return false;
      templateForm.disabled = true;
      setTimeout(() => {
        createTemplate();
      }, 1000);
    };

    const messageValidate = () => {
      let isValid = true;
      messageLoading.value = true;
      for (const item in form.items) {
        form.items[item].errors = [];
      }
      if (!form.items.content.value) {
        isValid = false;
        messageLoading.value = false;
        notify({
          type: "error",
          text: "فیلد خالی است .",
        });
      }
      if (!form.items.chat_id.value) {
        isValid = false;
        messageLoading.value = false;
        notify({
          type: "error",
          text: "سرویس را انتخاب کنید.",
        });
      }
      return isValid;
    };

    const messageSumbit = async () => {
      if (!messageValidate()) return false;
      form.disabled = true;
      setTimeout(() => {
        createMessage();
      }, 1000);
    };

    const selectTemplate = async (item) => {
      document.getElementById("mainText").value = item.content;
      document.getElementById("mainText").focus();
      showTemplates.value = false;
      form.items.content.value = item.content;
    };

    const getMessages = async () => {
      const response = await GetMessages(chat.id.value);

      if (response.data.success) {
        messages.value = response.data.data.map((i) => {
          return {
            updatedAtDate: new persianDate(new Date(i.updatedAt)).format(
              "YYYY-MM-DD "
            ),
            created_at: new persianDate(new Date(i.created_at)).format(
              "  HH:mm"
            ),
            first_message_of_the_day: i.first_message_of_the_day,
            client_id: i.client_id,
            chat_id: i.chat_id,
            content: i.content,
            id: i.id,
            first: new persianDate(new Date(i.created_at)).format(
              `dddd  DD  MMMM  YYYY`
            ),
            last_message_date: i.created_at,
          };
        });

        setTimeout(() => {
          let questions = document.querySelectorAll(".answer .message-title");
          questions.forEach((element) => {
            var r = Math.floor(Math.random() * 255);
            var g = Math.floor(Math.random() * 255);
            var b = Math.floor(Math.random() * 255);
            element.style.color = "rgb(" + r + "," + g + "," + b + ")";
          });
          document.getElementById("scroll-box").scrollTop =
            document.getElementById("scroll-box").scrollHeight;
        }, 5);
      } else if (response.data.status !== 401 && response.data.message) {
        notify({
          type: "error",
          text: response.data.message,
        });
      }
    };

    const close = async (item) => {
      eval(item + ".value " + " = " + false);
    };

    onMounted(async () => {
      getMemos();
      getChats();
      getMyChats();
      getServices();
      getClients();
      getColors();
      await getReport();
      initializeReportChart(
        ReportChartData.title,
        ReportChartData.labels,
        ReportChartData.data
      );
    });

    watch(() => {
      if (pageLoading.value) {
        document.querySelector(".karo-wrapper").classList.remove("isLoading");
      }
    });

    return {
      openRangeSelectList,
      memoLoading,
      memos,
      handelDeleteTemplate,
      deleteMemo,
      template,
      deleteTemplatePopup,
      deleteTemplateSuccess,
      close,
      memoForm,
      submit,
      chats,
      myChats,
      services,
      handleUpdateReportChart,
      pageLoading,
      ReportRange,
      colors,
      showChat,
      lockMessage,
      chat,
      selectChat,
      showColors,
      chatEdit,
      clients,
      showClients,
      selectClient,
      loading,
      templates,
      templateForm,
      getTemplates,
      showTemplates,
      templateSubmit,
      form,
      selectTemplate,
      messages,
      messageSumbit,
      messageLoading,
      user,
      selectUser,
      showUser,
      userDeta,
      userMemos,
      userForm,
      userLoading,
      createUserMemo,
      selectColor,
    };
  },
};
</script>

<style lang="scss">
@import "style.scss";
</style>